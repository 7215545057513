export type printerDataType = {
  id: number;
  name: string;
  address: string;
  deviceSerial: string;
  building_name: string;
  city_name: string;
  country_name: string;
  region_name: string;
  status: string;
  last_updated: string;
  document_output: string;
  terminal_client: string;
  embeddedConfigurationId: number;
};

export enum Action {
  INSTALL = 'install',
  UNINSTALL = 'uninstall',
  INSTALL_MOBILE = 'install_mobile',
  UNINSTALL_MOBILE = 'uninstall_mobile',
}

export type printerHistoryDataType = {
  providerId: number,
  uuid: string,
  documentName: string,
  documentType: number,
  userName: string,
  totalPages: number,
  colorPages: number,
  duplex: number,
  copies: number,
  grayscale: true,
  paperSize: string,
  dateTime: string,
  secure: false,
  collate: false,
  status: number,
  outputPortId: number,
  jobType: string
}

 export type historyResponseDataType = {
   documents: printerHistoryDataType[];
   portname: string;
 }

 export type filteredHistoryResponseDataType = {
   documents: Partial<printerHistoryDataType>[];
   portname: string;
 }

 export type outputPortCreationDetailsResponseDataType = {
  errorCode: number;
  id: number;
 }

 export type outputPortCreationResponseDataType = {
   printer_name: string;
   serial: string;
   ip: string;
   status: Boolean
   details: Partial<outputPortCreationDetailsResponseDataType>
 }
