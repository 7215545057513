import {jwtDecode} from "jwt-decode";

class Utils {
  checkIsValidToken = (token: string, cb: () => void) => {
    const decode: {
      exp: number;
    } = jwtDecode(token);
    if (decode.exp < +new Date() / 1000) {
      cb();
    }
  };

  arrayToCSV(data: Object[]) {
    function cleanValue(value: any): any {
      if (typeof value !== 'string') {
        return value;
      }

      return value
        .replace(/[\n\r^]/g, ' ')
        .trim()
        .replace(/\s{2,}/g, ' ');
    }

    const header = Object.keys(data[0])
      .map((key) => cleanValue(key))
      .join(",");

    const values = data.map((item) => {
      return Object.values(item)
        .map((val) => cleanValue(val))
        .join(",");
    });

    return [header, ...values].join("\n");
  }

  getFormattedUTCTime() {
    const date = new Date();

    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      timeZone: 'UTC',
      timeZoneName: 'short'
    });
    return formattedDate;
  }

  isIPAddressValid = (ip: string) => {
    const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipPattern.test(ip);
  }
}

export const utils_instance = new Utils();